import { graphql } from 'gatsby';
import React, { Component } from 'react';

import { BlogList } from '../components/BlogList';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';

type Props = {
  data: {
    allContentfulBlogPost: {
      edges: {
        node: {
          slug: string;
          title: string;
          publishDate: string;
          description: {
            childMarkdownRemark: {
              html: string;
            };
          };
          body: {
            childMarkdownRemark: {
              wordCount: {
                words: number;
              };
            };
          };
        };
      }[];
    };
  };
};

export default class Blog extends Component<Props> {
  render() {
    const { data } = this.props;
    return (
      <Layout>
        <SEO title="Blogs" />
        <Container>
          <h1>My Blogs</h1>
          <BlogList blogs={data.allContentfulBlogPost.edges.map(({ node }) => node)} />
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allContentfulBlogPost(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          slug
          title
          publishDate
          description {
            childMarkdownRemark {
              html
            }
          }
          body {
            childMarkdownRemark {
              wordCount {
                words
              }
            }
          }
        }
      }
    }
  }
`;
