import { navigate, Link } from 'gatsby';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { MinutesToRead } from './MinutesToRead';

type Blog = {
  slug: string;
  title: string;
  publishDate: string;
  description: {
    childMarkdownRemark: {
      html: string;
    };
  };
  body: {
    childMarkdownRemark: {
      wordCount: {
        words: number;
      };
    };
  };
};

export function BlogList({ blogs }: { blogs: Blog[] }) {
  return (
    <List>
      {blogs.map((blog, index) => (
        <ListItem key={index} onClick={() => navigate(`/blog/${blog.slug}`)}>
          <Title>
            <Arrow>→</Arrow> {blog.title}
          </Title>
          <SubTitle>
            {moment(blog.publishDate).format('LL')}
            <MinutesToRead words={blog.body.childMarkdownRemark.wordCount.words} />
          </SubTitle>
          <div
            dangerouslySetInnerHTML={{
              __html: blog.description.childMarkdownRemark.html,
            }}
          />
        </ListItem>
      ))}
    </List>
  );
}

const List = styled.ul`
  list-style: none;
  margin-left: 0;
`;

const Arrow = styled.span`
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  transition: opacity 0.2s, left 0.2s ease-in-out;
`;

const Title = styled.h3`
  transition: color 0.4s ease-in-out;
  position: relative;
  margin: 0;
`;

const ListItem = styled.li`
  position: relative;
  cursor: pointer;

  :hover {
    ${Arrow} {
      opacity: 1;
      left: -30px;
    }

    ${Title} {
      color: var(--color-primary10);
    }
  }
`;

const SubTitle = styled.h6`
  margin: 0;
  color: var(--color-gray700);
`;
